import { Skeleton } from 'antd';
import { Common, color, device } from '@stockbit/ui';
import styled, { css, keyframes } from 'styled-components';

const {
  Flex,
  IconBase64,
  Text,
  Avatar,
  Checkbox: CheckboxAntd,
  Input: AntdInput,
} = Common;

interface PanelContainerProps {
  visible?: boolean;
  [key: string]: any;
}

const DeletedLastMessage = styled(Text)`
  width: 90%;
  display: flex;
  align-items: center;
  font-style: italic;
  color: #858585;
`;

const PanelContainer = styled.div.attrs((props: PanelContainerProps) => ({
  visible: props.visible,
}))`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  width: 100vw;
  max-width: 100%;
  height: 100%;
  position: relative;
  @media ${device.tablet} {
    border-right: solid 1px ${color.downgray};
    display: block;
    width: 350px;
  }
`;

const PanelHeader = styled(Flex).attrs({
  padding: '14px 20px',
  justify: 'space-between',
  align: 'center',
})`
  border-bottom: 1px solid ${color.whisper};
`;

const IconNewChat = styled(IconBase64)`
  :hover {
    background-color: ${color.whisper};
  }
`;

const RoomListContainer = styled.div`
  overflow-y: auto;
  height: calc(100% - 61px);
`;

const ChatItem = styled(Flex)
  .withConfig({
    shouldForwardProp: (prop: any) => !['active'].includes(prop),
  })
  .attrs((props) => ({ active: props.active, align: 'center' }))`
  width: 100%;
  /* height : 75px; */
  border-bottom: 1px solid ${color.whisper};
  cursor : pointer;
  padding-left: ${({ active }) => (active ? '16px' : '20px')};
  padding-right: 20px;
  padding-top: 14px;
  padding-bottom: 15px;

  &:hover, &:active, &:focus {
    background-color: ${color.aliceBlue};
  }

  ${({ active }) =>
    active &&
    css`
      border-left: 5px solid ${color.primary};
      background-color: ${color.aliceBlue};
    `}

  ${({ cursorActive }) =>
    cursorActive &&
    css`
      background-color: ${color.aliceBlue};
    `}
`;

const NewChatCount = styled(Text).attrs({
  color: color.light,
  size: '11px',
  align: 'center',
  padding: '2px',
})`
  background-color: ${color.primary};
  border-radius: 50%;
  height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShimmerListRoomContainer = styled.div`
  padding: 16px 24px;
`;

const Shimmer = styled(Skeleton).attrs({
  avatar: true,
  paragraph: { rows: 1 },
  loading: true,
})`
  .ant-skeleton-title {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .ant-skeleton-paragraph {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
  }
  margin-bottom: 36px;
`;

interface RequestHeaderContainerProps {
  visible: boolean;
}

const RequestHeaderContainer = styled(Flex)<RequestHeaderContainerProps>`
  padding: 21px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 0;
  height: auto;
  transition: 0.5s;

  ${({ visible }) =>
    !visible &&
    `
    top: -50px;
    transition: .5s;
    width: 100%;
    background-color: #fff;
    height: 0;
    padding: 0 16px;
  `}
`;

const LeftMobileHeaderContainer = styled.div`
  display: block;
  @media ${device.mobileL} {
    display: none;
  }
`;

const ButtonUnstyled = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: min-content;
  overflow: visible;
  background: transparent;
  cursor: pointer;

  :hover {
    background: ${color.downgray};
  }
`;

interface InputSearchContainerProps {
  isFocus: boolean;
}

const InputSearchContainer = styled.div<InputSearchContainerProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    height: 32px;
  }

  #chat-search-back-button {
    position: absolute;
    left: -35px;
    transition: 0.2s;
  }

  #chat-search-new-chat-button {
    position: relative;
    right: 0;
    transition: 0.5s;
  }

  ${({ isFocus }) =>
    isFocus &&
    `
    #chat-search-back-button {
      position: relative;
      left: 0;
      transition: 0.5s;
    }
    #chat-search-new-chat-button {
      position: absolute;
      right: -90px;
      transition: .5s;
    }
  `};
`;

const RequestNumberContainer = styled.div`
  display: block;
  @media ${device.mobileL} {
    display: none;
  }
`;

const WebHeaderContainer = styled.div``;

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingMore = styled.div`
  min-width: 100px;
  min-height: 100px;
  position: relative;
  :before {
    content: 'Loading…';
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    margin-top: 0;
    margin-left: 0;
  }
  :before {
    content: '';
    border-radius: 50%;
    border-top: 2px solid #ccc;
    border-right: 2px solid transparent;
    animation: ${loadingAnimation} 0.7s linear infinite;
    -webkit-animation: ${loadingAnimation} 0.7s linear infinite;
  }
`;

const RoomItemContent = styled(Flex).attrs({
  justify: 'space-between',
  align: 'center',
})`
  display: flex;
  justify-content: 'space-between';
  align-items: 'center';
  flex-grow: 1;

  p > img {
    margin-right: 5px;
  }
`;

interface RoomItemBodyProps {
  hasCheckbox: boolean;
}

const RoomItemBody = styled.div<RoomItemBodyProps>`
  width: calc(100% - 60px);

  ${({ hasCheckbox }) =>
    hasCheckbox &&
    css`
      width: calc(100% - 100px);
    `}
`;

const RoomItemStatus = styled(Flex)`
  img {
    width: 16px;
  }
  & > :last-child {
    margin-left: 5px;
  }
`;

const GroupAvatar = styled.span`
  width: 46px;
  height: 46px;
  line-height: 46px;
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  border-radius: 100%;
  overflow: hidden;
  background: #ebf8f3;
  color: #00ab6b;
  i {
    font-style: normal;
  }
`;

const AvatarContainer = styled(Avatar)`
  padding-right: 12px;

  & > img {
    border-radius: 50%;
    width: 46px;
    height: 46px;
    border: 1px solid ${color.component};
    background: ${color.lowgray};
  }
`;

const SearchInput = styled(AntdInput).attrs({
  type: 'text',
})`
  border: 1px solid ${color.gainsboro};
  border-radius: 4px;
  height: 32px;

  :hover {
    border-color: ${color.gainsboro} !important;
  }

  input::placeholder,
  input::-webkit-input-placeholder {
    color: #e4e4e4 !important;
  }

  .ant-input-prefix {
    margin-right: 10px;
  }

  .ant-input-clear-icon {
    display: flex;
    color: rgba(0, 0, 0, 0.5);
  }

  #chat-left-contact-search-close {
    width: 16px;
    height: 16px;
    opacity: 0.5;
  }
`;

const ButtonHeaderRequestAction = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  height: min-content;
  overflow: visible;
  background: transparent;
  color: ${color.primary};
  width: 55px;
  text-align: right;
  cursor: pointer;

  :hover {
    background: none;
  }
`;

const Checkbox = styled(CheckboxAntd)`
  margin-right: 15px;
  .ant-checkbox-inner {
    border-radius: 100% !important;
    width: 18px;
    height: 18px;
    position: relative;

    :after {
      left: 3.5px !important;
      top: 7px !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${color.primary};
    border-color: ${color.primary}!important;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: ${color.lowgray}!important;
    background-color: ${color.lowgray};
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-top: 1px solid ${color.whisper};
  background-color: ${color.light};
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const FooterActionButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  height: min-content;
  overflow: visible;
  background: transparent;
  display: flex;
  align-items: center;
  font-family: var(--font-bold);
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  :hover {
    background: none;
  }
`;

export {
  DeletedLastMessage,
  PanelContainer,
  PanelHeader,
  IconNewChat,
  ChatItem,
  NewChatCount,
  RequestHeaderContainer,
  ButtonUnstyled,
  RoomListContainer,
  ShimmerListRoomContainer,
  Shimmer,
  InputSearchContainer,
  RequestNumberContainer,
  WebHeaderContainer,
  LeftMobileHeaderContainer,
  LoadingMore,
  RoomItemContent,
  RoomItemBody,
  RoomItemStatus,
  GroupAvatar,
  AvatarContainer,
  SearchInput,
  ButtonHeaderRequestAction,
  Checkbox,
  FooterContainer,
  FooterActionButton,
};
