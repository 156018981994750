import { API_EXODUS_URL } from 'constants/api';

import exodusInstance from 'lib/api/instances/exodus';
import { APIPromiseResponse, APIResponse } from '../../../@types/api-response';
import { NotificationSettingsList } from '../../../@types/notifications';

const requestExodusDefault = exodusInstance.defaultInstance;
const requestExodus = exodusInstance.noAlert;

const USER_SETTING_URL = `${API_EXODUS_URL}/user/setting`;
const INVITATION_SETTING_URL = `${API_EXODUS_URL}/chat/v2/invitations/setting`;
const NOTIFICATION_SETTING_URL = `${API_EXODUS_URL}/notification/settings`;
const NOTIF_V2_SETTING_URL = `${API_EXODUS_URL}/notification/v2/settings`;

interface updatePasswordParams {
  change_token: string;
  new: string;
  verify: string;
}

const getChangePasswordToken = (data): Promise<APIResponse> =>
  requestExodusDefault.post(`${USER_SETTING_URL}/token`, data);

const updatePassword = (data: updatePasswordParams): Promise<APIResponse> =>
  requestExodusDefault.put(`${USER_SETTING_URL}/password`, data);

const getNotificationSettings = (): Promise<
  APIResponse<NotificationSettingsList>
> => requestExodusDefault.get(`${NOTIF_V2_SETTING_URL}`);

interface updateNotifParams {
  type: string | number;
  settings: {
    key: string;
    value: boolean;
  }[];
}
const updateNotificationSetting = (
  data: updateNotifParams,
): Promise<APIResponse> =>
  requestExodus.put(`${NOTIFICATION_SETTING_URL}`, data);

const getInvitationSetting = (): Promise<APIResponse> =>
  requestExodus.get(`${INVITATION_SETTING_URL}`);

const updateInvitationSetting = (data): Promise<APIResponse> =>
  requestExodusDefault.put(`${INVITATION_SETTING_URL}`, data);

// Update email
const updateEmail = (data): APIPromiseResponse =>
  requestExodusDefault.post(`${USER_SETTING_URL}/email`, data);

const confirmEmailOTP = (data): APIPromiseResponse =>
  requestExodusDefault.post(`${USER_SETTING_URL}/email/confirm`, data);

// Update phone
const updatePhone = (data): APIPromiseResponse =>
  requestExodusDefault.post(`${USER_SETTING_URL}/phone`, data);

const confirmPhoneOTP = (data): APIPromiseResponse =>
  requestExodusDefault.post(`${USER_SETTING_URL}/phone/confirm`, data);

export default {
  getChangePasswordToken,
  updatePassword,
  getNotificationSettings,
  updateNotificationSetting,
  getInvitationSetting,
  updateInvitationSetting,
  updateEmail,
  confirmEmailOTP,
  updatePhone,
  confirmPhoneOTP,
};
