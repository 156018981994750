import { createContext, FC, useCallback, useEffect, useRef } from 'react';
import { PROXY_CLIENT_CONFIG } from 'constants/app';
import auth from 'utils/auth';
import useFeatureFlagStore from './store';
import { UnleashInstance } from './UnleashInstance';

interface UnleashProviderProps {
  isLoggedIn?: boolean;
  userId?: number | string;
}

export const UnleashContext = createContext<UnleashInstance>(undefined);

export const UnleashProvider: FC<UnleashProviderProps> = ({
  isLoggedIn,
  userId,
  children,
}) => {
  const unleashInstance = useRef<UnleashInstance>(
    new UnleashInstance(PROXY_CLIENT_CONFIG),
  );
  const { setAllFlagValue, setReady } = useFeatureFlagStore();

  const getUserId = useCallback(() => {
    if (!isLoggedIn) return null;
    //  @ts-ignore: Property 'id' does not exist on type 'string'.
    return userId || auth.getUserAccess()?.id;
  }, [userId, isLoggedIn]);

  const updateUnleashUserId = useCallback(() => {
    unleashInstance.current.setContextField('userId', getUserId());
  }, [getUserId]);

  const stopUnleash = () => {
    unleashInstance.current.stop();
    updateUnleashUserId();
  };

  const startUnleash = async () => {
    if (unleashInstance.current.isAlive) return;

    updateUnleashUserId();

    unleashInstance.current.on('ready', () => {
      setReady(true);
      setAllFlagValue(unleashInstance.current);
    });
    unleashInstance.current.on('update', () => {
      setAllFlagValue(unleashInstance.current);
    });

    await unleashInstance.current.start();
  };

  // Update user id when side-effects triggered
  useEffect(() => {
    updateUnleashUserId();
  }, [updateUnleashUserId]);

  useEffect(() => {
    startUnleash();

    return () => {
      stopUnleash();
    };
  }, []);

  return (
    <UnleashContext.Provider value={unleashInstance.current}>
      {children}
    </UnleashContext.Provider>
  );
};
