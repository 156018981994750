import create, { StateCreator } from 'zustand';
import featureFlags from 'constants/featureFlags';
import produce from 'immer';
import { UnleashInstance } from './UnleashInstance';
import { FlagValue } from '../../../@types/unleash';

interface State {
  flagList: Record<FlagValue, boolean>;
  isReady: boolean;
}

interface Actions {
  setReady: (value: boolean) => void;
  setAllFlagValue: (instance: UnleashInstance) => void;
}

const initialState: State = {
  flagList: Object.values(featureFlags).reduce(
    (prev, curr) => ({ ...prev, [curr]: false }),
    {},
  ) as Record<FlagValue, boolean>,
  isReady: false,
};

const actions: StateCreator<Actions & State, [], [], Actions> = (set) => ({
  setReady: (value) => {
    set((state) =>
      produce(state, (draft) => {
        draft.isReady = value;
      }),
    );
  },
  setAllFlagValue: (instance) => {
    set((state) =>
      produce(state, (draft) => {
        Object.values(featureFlags).forEach((key) => {
          draft.flagList[key] = instance.isEnabled(key);
        });
      }),
    );
  },
});

const useFeatureFlagStore = create<State & Actions>(
  (set, get, store, storeMutation) => ({
    ...initialState,
    ...actions(set, get, store, storeMutation),
  }),
);

export default useFeatureFlagStore;
