import { Common, color, Widget } from '@stockbit/ui';
import styled, { css } from 'styled-components';
import { Tabs } from 'antd';

const { Flex, Text, DatePicker: DatePickerDefault, Button, Select } = Common;
const { Table } = Widget;

const cellType = {
  up: color.primary,
  down: color.sunsetOrange,
  neutral: color.suvaGrey,
  default: color.highempahis,
  local: color.purpleHeart,
  bumn: color.green,
  // TODO add color to DS
  foreign: '#D73F3C',
  newForeign: '#D73F3C',
};

const HeaderContainer = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
  px: '10px',
})`
  position: relative;
  background: ${color.aliceBlue};
  height: 40px;
  cursor: pointer;
  border-bottom: 1px solid ${color.gainsboro};
`;

const Td = styled(Text).attrs((props) => ({
  height: 'auto',
  padding: '6px 0px',
  size: '13px',
  type: props.type || 'default',
}))`
  cursor: pointer;

  ${({ bgType }) => css`
    background-color: ${bgType};
  `}

  ${({ type }) =>
    css`
      color: ${cellType[type]};
    `}

  ${({ weight }) =>
    weight === 'bold' &&
    css`
      font-family: 'ProximaNovaBd';
    `}

  ${({ minWidth }) => css`
    min-width: ${minWidth};
  `}
`;

const Th = styled(Td).attrs({
  padding: '6px 0px',
  size: '13px',
  whiteSpace: 'nowrap',
})``;

const DatePicker = styled(DatePickerDefault)`
  flex: 1;
  height: 32px;

  :nth-child(1) {
    border: none !important;
  }

  :nth-child(3) {
    border: none !important;
  }

  :hover,
  :focus {
    :nth-child(1) {
      border: none !important;
    }

    :nth-child(3) {
      border: none !important;
    }
  }

  input {
    color: ${color.primary};
  }

  input::placeholder {
    color: ${color.primary};
  }

  .ant-picker-content {
    table-layout: auto;
  }
`;

const Box = styled.div`
  overflow-y: auto;

  & > div::-webkit-scrollbar {
      width: 0px;
  }
`;

const SearchWrapper = styled.div`
  padding: 0 12px;
  margin-bottom: 12px;
`;

const StyledTabs = styled(Tabs)`
  & {
    min-height: 100%;
  }

  .ant-tabs-nav {
    margin-bottom: 12px;

    &::before {
      border: none;
    }
  }

  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      justify-content: space-between;
      width: 100%;
    }
  }

  .ant-tabs-ink-bar {
    background: transparent;

    &::after {
      background: #00ab6b;
      content: ' ';
      position: absolute;
      left: 50%;
      right: 0;
      bottom: 5px;
      height: 2px;
      width: 40px;
      transform: translateX(-50%);
    }
  }

  .ant-tabs-tab {
    font-size: 13px;
    margin: 0;
    padding: 14px 12px;

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: ${color.highempahis};
        text-shadow: none;
        font-family: 'ProximaNovaBd';
      }
    }

    &:hover {
      color: unset;
    }
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

const DatePickerWrapper = styled(Flex)`
  align-items: center;
  border: 1px solid ${color.gainsboro};
  border-radius: 2px 2px 0 0;

  .ant-picker {
    padding: 8px;
  }
  .ant-picker:last-child .ant-picker-input input {
    text-align: right;
  }
  .ant-picker:last-child .ant-picker-input .ant-picker-suffix {
    margin-left: 8px;
  }
`;

const SelectWrapper = styled(Flex)`
  > :first-child {
    width: 100%;

    .ant-select-selector {
      border-top: none;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  > :nth-child(2) {
    width: 100%;

    .ant-select-selector {
      border-left: none;
      border-radius: 0;
      border-right: none;
      border-top: none;
    }
  }

  > :last-child {
    width: 80%;

    .ant-select-selector {
      border-top: none;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .ant-select-selector {
    padding: 0 8px !important;
  }
`;

const BrokerActivityWrapper = styled.div`
  padding: 0 12px;
  min-height: 400px;
`;

const BrokerActivityTabelWrap = styled.div`
  margin-top: 12px;
  overflow-x: auto;

  thead th {
    background: transparent;
    border-bottom: 1px solid ${color.gainsboro} !important;
  }
`;

const BrokActivityText = styled(Text).attrs((props) => ({
  height: 'auto',
  size: '13px',
  align: 'center',
  padding: '6px 0px',
  type: props.type || 'default',
}))`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;

  ${({ type }) =>
    css`
      color: ${cellType[type]};
    `}
`;

const CodeTag = styled.span.attrs((props) => ({
  type: props.className || 'default',
}))`
  font-size: 14px;
  font-family: 'ProximaNovaBd';
  display: inline-block;
  width: 30px;

  ${({ type }) =>
    css`
      color: ${cellType[type]};
    `}
`;

const BrokerListSelect = styled(Select).attrs(() => ({
  style: {
    width: '100%',
    marginBottom: 12,
  },
}))`
  ${CodeTag} {
    width: auto;
  }
  ${CodeTag}::after {
    content: ' -';
    margin: 0 4px;
    color: ${color.highempahis};
    font-family: 'ProximaNovaRg';
  }
`;

const TableListTransaction = styled(Table)`
  .ant-table-container {
    table {
      tr {
        th {
          background-color: transparent;
          border-bottom: 1px solid ${color.gainsboro};
        }

        .ant-table-cell {
          white-space: nowrap;
        }

        th:not(:first-child) p,
        td:not(:first-child) p {
          margin-left: 8px;
        }
      }
    }
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right)
    .ant-table-container::after {
    box-shadow: none;
  }
`;

const TableBrokerAccDist = styled(Table)`
  width: 100%;

  .ant-table-container {
    table {
      tbody:before {
        content: '@';
        display: block;
        line-height: 8px;
        text-indent: -99999px;
      }
      tr {
        th {
          background-color: transparent;
          border-bottom: 1px solid ${color.gainsboro};
        }

        th:nth-child(2) p,
        td:nth-child(2) p,
        th:nth-child(3) p,
        td:nth-child(3) p,
        th:nth-child(4) p,
        td:nth-child(4) p {
          text-align: right;
          padding-right: 8px;
        }
      }
    }
  }
`;

const TableBrokerSummary = styled(Table)`
  width: 100%;

  .ant-table-container {
    table {
      tr {
        th:nth-child(2) p,
        th:nth-child(3) p,
        th:nth-child(4) p,
        td:nth-child(2) p,
        td:nth-child(3) p,
        td:nth-child(4) p {
          text-align: right;
        }
      }
      tr td:nth-child(4) p {
        padding-right: 8px;
      }
    }
  }
`;

const TableBrokerWrapper = styled(Flex)`
  flex-direction: column;
  padding: 0 12px;
  margin-bottom: 12px;
`;

const TableTransactionBroker = styled(Table)`
  margin-bottom: 12px;

  .ant-table-container {
    padding: 0 12px;

    table {
      tr {
        th {
          background-color: transparent;
          border-bottom: 1px solid ${color.gainsboro};
        }

        th:nth-child(2) p,
        td:nth-child(2) p,
        th:nth-child(3) p,
        td:nth-child(3) p,
        th:nth-child(4) p,
        td:nth-child(4) p {
          text-align: right;
        }
        th:not(:last-child) p,
        td:not(:last-child) p {
          padding-right: 8px;
        }
      }

      tbody:before {
        content: '@';
        display: block;
        line-height: 8px;
        text-indent: -99999px;
      }
    }
  }
`;

const BandarSearchCompanyWrapper = styled.div`
  margin-bottom: 12px;
`;

const ButtonOpenModal = styled(Button)`
  cursor: pointer;
  position: fixed;
  bottom: 13px;
  right: 50px;
  width: 32px;
  height: 32px;
  padding: 8px;
  color: ${color.highgray};
  font-weight: 700;
  background: ${color.downgray};
  border: 1px solid ${color.gainsboro};
  border-radius: 2px;
`;

const ModalWrapper = styled.div`
  padding: 10px;

  .ant-modal {
    padding: 25px;
  }
`;

const ModalTitle = styled.div`
  padding: 25px 20px 20px 20px;

  .top-broker-title {
    font-family: 'ProximaNovaBd';
    font-size: 18px;
    font-weight: 700;
  }

  .top-broker-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #858585;
  }
`;

const TableTopBroker = styled(Table)`
  .ant-table-ping-left:not(.ant-table-has-fix-left)
    .ant-table-container::before {
    box-shadow: none;
  }

  .ant-table-container {
    padding: ${(props) => (props.isModal ? 0 : '0 12px')};

    table {
      tr {
        th {
          background-color: #ffffff;

          p {
            padding: 5px 0;
            font-weight: 700;
            font-family: 'ProximaNovaBd';
          }

          &.ant-table-column-has-sorters {
            &:hover {
              background-color: #ffffff;
            }

            .ant-table-column-sorters {
              padding: 0;

              .ant-table-column-sorter {
                display: none;
              }
            }
          }

          &.ant-table-column-sort {
            .ant-table-column-sorters {
              .ant-table-column-sorter {
                margin-left: 4px;
                display: inline-block;
              }
            }
          }
        }

        td {
          &.ant-table-column-sort {
            background-color: #ffffff;
          }
        }
      }
    }
  }
`;

const WrapperTransactionList = styled.div`
  padding: 0 12px;
`;

export {
  HeaderContainer,
  Td,
  Th,
  DatePicker,
  Box,
  SearchWrapper,
  StyledTabs,
  DatePickerWrapper,
  SelectWrapper,
  TableListTransaction,
  TableBrokerAccDist,
  TableBrokerWrapper,
  TableBrokerSummary,
  TableTransactionBroker,
  TableTopBroker,
  BandarSearchCompanyWrapper,
  BrokerActivityWrapper,
  BrokerActivityTabelWrap,
  BrokActivityText,
  BrokerListSelect,
  CodeTag,
  ButtonOpenModal,
  ModalWrapper,
  ModalTitle,
  WrapperTransactionList,
};
