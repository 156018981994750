import {
  OrderSearchValue,
  OrderFilterValue,
  iRecord as OrderItem,
} from './types';

const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x);

const splitSearch = (search = '', selectedStock = ''): OrderSearchValue => {
  const arrSearch = search.split(' ');
  if (arrSearch.length === 1) {
    return {
      action: selectedStock || arrSearch[0],
      stock: selectedStock || arrSearch[0],
      text: selectedStock || arrSearch[0],
    };
  }
  if (arrSearch.length > 1) {
    return {
      action: arrSearch[0],
      stock: selectedStock || arrSearch[1],
      text: [arrSearch[0], selectedStock || arrSearch[1]].join(' '),
    };
  }
  return {
    action: '',
    stock: '',
    text: '',
  };
};

const search = (source: OrderItem[], searchValue: OrderSearchValue) => {
  if (!searchValue) {
    return source;
  }

  const { action, stock } = searchValue;
  if (action !== '' && action === stock) {
    return source.filter(
      (e: OrderItem) =>
        e.action.startsWith(action.toLocaleUpperCase()) ||
        e.symbol.startsWith(action.toLocaleUpperCase()),
    );
  }

  if (action !== '' && stock !== '') {
    return source.filter(
      (e: OrderItem) =>
        action.toLocaleUpperCase() === e.action &&
        e.symbol.startsWith(stock.toLocaleUpperCase()),
    );
  }

  return source;
};

const filter = (source: OrderItem[], filterValue: OrderFilterValue) => {
  if (!filterValue) {
    return source;
  }
  const { status, orderType, expiry, accountType = [] } = filterValue;

  const filterFn = (
    valueToCheck: string,
    filterValueItem: string[] = [],
  ): boolean => {
    if (filterValueItem?.length === 0) {
      return true;
    }
    return filterValueItem?.includes(valueToCheck);
  };

  const filterStatus = (s: OrderItem[]): OrderItem[] =>
    s.filter((i: OrderItem) => filterFn(i.status?.toLocaleLowerCase(), status));
  const filterOrderType = (s: OrderItem[]): OrderItem[] =>
    s.filter((i: OrderItem) => filterFn(i.label?.toUpperCase(), orderType));
  const filterExpiry = (s: OrderItem[]): OrderItem[] =>
    s.filter((i: OrderItem) => filterFn(i.gtc?.toString(), expiry));
  const filterAccountType = (s: OrderItem[]): OrderItem[] =>
    s.filter((i: OrderItem) => filterFn(i.day_trade_is_day_trade?.toString(), accountType));

  return pipe(
    filterStatus,
    filterOrderType,
    filterExpiry,
    filterAccountType,
  )(source);
};

const checkFilterAvailability = (filterValue: OrderFilterValue): boolean => {
  const filterArr = Object.keys(filterValue).map((k) => filterValue[k]?.length);
  return filterArr.some((i) => i > 0);
};

export { splitSearch, search, filter, checkFilterAvailability };
