export enum SecuritiesChannel {
  PING = 'ping',
  AUTH = 'auth',
  SECURITIES = 'securities',
}

export enum PayloadCase {
  COMMAND = 'command',
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
}

export enum RequestCommand {
  UNSPECIFIED = 0,
  PING = 1,
  AUTH = 2,
}

export enum RequestChannel {
  UNSPECIFIED = 0,
  SECURITIES_LIVE_PORTO = 1,
}

interface PayloadAction {
  case: PayloadCase;
  value: RequestCommand | RequestChannel;
}

export interface ChannelPayload {
  action: PayloadAction;
  value: Uint8Array;
}

type Channel = {
  [key in SecuritiesChannel]: ChannelPayload;
};

export interface State {
  channel: Partial<Channel>;
  isReconnectionFailed: boolean;
  isSocketOnline: boolean;
}

export interface Actions {
  setChannel: (key: SecuritiesChannel, value: ChannelPayload) => void;
  setIsReconnectionFailed: (value: boolean) => void;
  setIsSocketOnline: (isSocketOnline: boolean) => void;
}
