import { IConfig, UnleashClient } from 'unleash-proxy-client';

export class UnleashInstance extends UnleashClient {
  isAlive: boolean;

  constructor(config: IConfig) {
    super(config);
    this.isAlive = false;
  }

  async start(): Promise<void> {
    await super.start();
    this.isAlive = true;
  }

  stop(): void {
    super.stop();
    this.isAlive = false;
  }
}
